// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-aec-page-js": () => import("./../../../src/templates/aec-page.js" /* webpackChunkName: "component---src-templates-aec-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-industrial-page-js": () => import("./../../../src/templates/industrial-page.js" /* webpackChunkName: "component---src-templates-industrial-page-js" */),
  "component---src-templates-normal-page-js": () => import("./../../../src/templates/normal-page.js" /* webpackChunkName: "component---src-templates-normal-page-js" */),
  "component---src-templates-overview-page-js": () => import("./../../../src/templates/overview-page.js" /* webpackChunkName: "component---src-templates-overview-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */)
}

